import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { parmsArray } from '@/config/data'

export const object = [{
    type: 'select',
    name: t('currencySet.name1'),
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    postData: [],
    isPlatform: 1,
    props:{
        value:'id',
        label:'name'
    }
},{
    type: 'select',
    name: t("adminMessM.name3"),
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],
    props:{
        value:'appId',
        label:'appName'
    }
},{
    type: 'input',
    name: t("adminMessM.name4"),
    prop: 'msgFrom'
},{
    type: 'select',
    name: t("adminMessM.name7"),
    prop: 'templateCode',
    isParms: parmsArray[6]
},{
    type: 'datetime',
    name: t("adminMessM.name8"),
    prop: 'createTime'
}]