<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
            <template #status="scope">
                {{  getDataAboutText(5,scope.row.status)}}
            </template>
            <template #msgType="scope">
                {{  getDataAboutText(7,scope.row.msgType)}}
            </template>
        </avue-crud>
    </div>
</template>
<script setup>
    import { ref } from 'vue'
    import { tableOption1 } from "@/const/crud/messManagement";
    import fromSearch from '@/components/fromSearch.vue'
    import { object } from '@/const/from/admin/sms'
    import { turnTimeFun } from '@/utils/util.js' 
    import { getDataAboutText,getDataStyle } from '@/utils/dataBind/parmsView'  
    import { parmsArray } from '@/config/data'

    import mixins from '@/views/mixins/page'
    let pageObj = mixins(['getMessManagementList'],{descs: "create_time"})
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj
    getDataStyle(parmsArray[5],()=>{})
    
    const tableOption = ref({});tableOption.value = tableOption1
    const object_ = ref(object);

    getList(1)
</script>

<style lang="scss" scoped>
</style>