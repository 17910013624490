
import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = {
        border: true,
        index: true,
        indexLabel: t("adminMessM.name1"),
        indexWidth: 80,
        stripe: true,
        menuAlign: 'center',
        height: 'auto',
        searchMenuSpan: 6,
        editBtn: false,
        delBtn: false,
        align: 'center',
        refreshBtn: false,
        columnBtn: false,
        addBtn: false,
        viewBtn: false,
        menu:false,
        column: [{
            label: t("currencySet.name1"),
            prop: 'tenantName',
            span: 24,
            // 'search': true,
        }, {
            label: t("adminMessM.name3"),
            prop: 'appName',
            span: 24,
            // 'search': true,
        }, {
            width: 120,
            label: t("adminMessM.name4"),
            prop: 'msgFrom',
            // 'search': true,
        }, {
            width: 150,
            label: t("adminMessM.name5"),
            prop: 'msgContent'
        },{
            width: 140,
            label: t("adminMessT.name5"),
            prop: 'msgType',
        },{
            width: 130,
            label: t("adminMessM.name7"),
            prop: 'templateCode',
            // 'search': true,
        },
        {
            width: 130,
            label: t("adminMessM.name8"),
            prop: 'createTime',
        }, 
        {
            width: 130,
            label: t("adminMessM.name9"),
            prop: 'updateTime',
        },{
            width: 130,
            label: t("adminMessM.name10"),
            prop: 'status',
        },{
            label: t("adminMessM.name11"),
            prop: 'updateBy',
        },]
    }